@import "../../../scss/styles.scss";

.newsletter-section {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    background: #f9f9f9 url("../../../assets/newsletter-bg.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .newsletter-content {
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        text-align: center;
    }
    .big-text {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 30px;
        @include md {
            font-size: 23px;
            font-weight: 600;
        }
    }
    .form {
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
        input {
            width: 200px;
            height: 40px;
            border-radius: 0px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            padding: 0 12px;
            font-size: 16px;
            outline: none;
            @include md {
                width: 300px;
            }
        }
        button {
            outline: none;
            border: none;
            height: 40px;
            min-width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16px;
            color: #fff;
            background-color: rgb(246, 95, 1);
            border-bottom: 3px solid rgb(246, 95, 1);
            &:hover {
                opacity: 0.8;
                transition: all ease-out 0.3s;
            }
            @include md {
                width: 120px;
            }
        }
    }
    .text {
        margin-bottom: 20px;
        font-size: 15px;
    }
    .social-icon {
        display: flex;
        gap: 10px;
        .icon {
            width: 30px;
            height: 30px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
    }
}
