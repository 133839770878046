@import "../../../scss/styles.scss";

.product-card {
    cursor: pointer;
    width: calc(50% - 5px);
    margin-bottom: 20px;
    border-radius: 10px;
    min-height: 200px;

    @include md {
        width: calc(25% - 15px);
        height: 455px;
    }

    .thumbnail {
        width: 100%;
        height: 180px;
        margin-bottom: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        height: 250px;
        img {
            transition: all ease 0.3s;
            display: block;
            width: 100%;
            height: 230px;
            border-radius: 10px;
            // @include md {
            //     height: 280px;
            // }
        }

        @include md {
            height: 350px;
            img {
                transition: all ease 0.3s;
                display: block;
                width: 100%;
                height: 350px;
                border-radius: 10px;
            }
        }
    }

    .product-detail {
        a {
            text-decoration: none;
        }

        .name {
            font-size: 14px;
            display: block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-weight: bold;
            margin-bottom: 5px;
            color: #333;

            &:hover {
            }

            @include md {
                font-size: 16px;
            }
        }

        .product-bottom {
            .price-star {
                .star {
                    color: rgb(246, 95, 1);
                    font-size: 15px;
                }
                .price {
                    font-size: 14px;
                    font-weight: bold;
                    float: right;
                    color: #333;

                    @include md {
                        font-size: 14px;
                        margin-bottom: 2px;
                        float: right;
                    }
                }
            }
            .add-cart-btn {
                padding-top: 5px;
                .add-to-cart-btn {
                    background-color: transparent;
                    border-radius: 50px;
                    min-width: 120px;
                    height: 35px;
                    border: 1.5px solid rgb(246, 95, 1);
                    font-size: 13px;
                    font-weight: 600;
                    &:hover {
                        color: #fff;
                        cursor: pointer;
                        background-color: rgb(246, 95, 1);
                        transition: all ease 0.3s;
                    }
                }
            }
        }
    }
    // &:hover {
    //     font-weight: bold;
    //     .thumbnail {
    //         img {
    //             transform: scale(1.02);
    //         }
    //     }
    // }
}
