@import "../../scss/styles.scss";

.payment-heading {
    text-align: center;
    .title-payment {
        padding-top: 50px;
        padding-bottom: 15px;
        font-size: 35px;
        color: #333;
    }
    p {
        padding-bottom: 30px;
    }
}
.row-payment {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    display: block;
    @include md {
        display: flex;
        align-items: flex-start;
    }

    .payment-input {
        display: block;
        width: 100%;
        height: 38px;
        padding: 10px;
        border-radius: 5px;
        background-color: #f2f3f5;
        outline: none;
        border: 0;
        font-size: 17px;
        margin-bottom: 15px;
    }

    .payment-input::-webkit-input-placeholder {
        color: #b4bac0;
    }

    .payment-label {
        font-size: 16px;
        color: #373941;
        display: inline-block;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .type-payment {
        margin-top: 30px;
        .title-type-payment {
            font-size: 18px;
            color: #333;
        }
        p {
            padding-bottom: 10px;
        }
    }

    .payment-submit {
        font-weight: 600;
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        color: #fff;
        outline: none;
        border: 0;
        font-size: 17px;
        background-color: rgb(246, 95, 1);
        border-radius: 5px;
        text-align: center;
        display: inline-block;
        width: 100%;
        height: 50px;
        margin-top: 25px;
        margin-bottom: 50px;
    }
}

.row-product-payment {
    display: flex;
    border-bottom: 1px solid rgb(171, 171, 171);
    .total-price-product {
        float: right;
    }
}
.row-total-payment {
    display: flex;
}

.title-subtotal-product {
    font-size: 18px;
    font-weight: bold;
}

.col-2 {
    align-items: flex-start;
}

.payment-title {
    padding-bottom: 15px;
    text-decoration: underline;
}

.priceItem {
    color: #444 !important;
    font-size: 15px;
}

.totalPriceItem {
    font-weight: 500;
}

.subTotal {
    font-weight: bold;
}

.col-2-payment {
    width: 45%;
}

.price-payment {
    float: right;
}

.col-2-items-payment {
    width: 50%;
}

.name-product-payment {
    padding-top: 10px;
}

.col-2-checkout {
    width: 50%;
}
p.subTotal {
    float: right;
}

.row-total-payment {
    padding-top: 30px;
}

.totalprice {
    padding-top: 10px;
    float: right;
}
