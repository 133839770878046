@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:wght@400;500;600;700&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

:root {
    --poppins: "Poppins", sans-serif;
    --lato: "Lato", sans-serif;

    --light: #f9f9f9;
    --blue: #3c91e6;
    --light-blue: #cfe8ff;
    --grey: #eee;
    --dark-grey: #aaaaaa;
    --dark: #342e37;
    --red: #db504a;
    --yellow: #ffce26;
    --light-yellow: #fff2c6;
    --orange: #fd7238;
    --light-orange: #ffe0d3;
}

html {
    overflow-x: hidden;
}

body.dark {
    --light: #0c0c1e;
    --grey: #060714;
    --dark: #fbfbfb;
}

body {
    background: var(--grey);
    overflow-x: hidden;
}

/* SIDEBAR */
#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100%;
    background: var(--light);
    z-index: 2000;
    font-family: "Inter", sans-serif;
    transition: 0.3s ease;
    overflow-x: hidden;
    scrollbar-width: none;
}
#sidebar::--webkit-scrollbar {
    display: none;
}
#sidebar.hide {
    width: 60px;
}
#sidebar .brand {
    font-size: 24px;
    font-weight: 700;
    height: 56px;
    display: flex;
    align-items: center;
    color: var(--blue);
    position: sticky;
    top: 0;
    left: 0;
    background: var(--light);
    z-index: 500;
    padding-bottom: 20px;
    box-sizing: content-box;
}
#sidebar .brand .bx {
    min-width: 60px;
    // display: flex;
    justify-content: center;
}
#sidebar .side-menu {
    // width: 100%;
    // margin-top: 48px;
    // margin-left: 20px;
}
#sidebar .side-menu li {
    height: 50px;
    background: transparent;
    border-radius: 48px 0 0 48px;
    width: 100%;
}

#sidebar .side-menu li a {
    padding-left: 40px;
    p {
        font-size: 17px;
    }
}
#sidebar .side-menu li.active {
    background: var(--grey);
    position: relative;
}
#sidebar .side-menu li.active::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -40px;
    right: 0;
    box-shadow: 20px 20px 0 var(--grey);
    z-index: -1;
}
#sidebar .side-menu li.active::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 70px;
    border-radius: 50%;
    bottom: -40px;
    right: 0;
    box-shadow: 20px -20px 0 var(--grey);
    z-index: -1;
}
#sidebar .side-menu li a {
    width: 100%;
    height: 55px;
    background: var(--light);
    display: flex;
    align-items: center;
    // border-radius: 48px;
    font-size: 16px;
    color: var(--dark);
    white-space: nowrap;
    overflow-x: hidden;
}
#sidebar .side-menu.top li.active a {
    color: var(--blue);
}
#sidebar.hide .side-menu li a {
    width: calc(48px - (4px * 2));
    transition: width 0.3s ease;
}

#sidebar .side-menu.top li a:hover {
    // color: var(--blue);
    transition: all ease-in-out 0.4s;
    // opacity: 0.7;
    background-color: rgb(220, 220, 220);
}
#sidebar .side-menu li a .bx {
    min-width: calc(60px - ((4px + 6px) * 2));
    display: flex;
    justify-content: center;
}
/* SIDEBAR */

/* CONTENT */
#content {
    position: relative;
    width: calc(100% - 280px);
    left: 280px;
    transition: 0.3s ease;
}
#sidebar.hide ~ #content {
    width: calc(100% - 60px);
    left: 60px;
}

/* NAVBAR */
#content nav {
    height: 56px;
    background: var(--light);
    padding: 0 24px;
    display: flex;
    align-items: center;
    grid-gap: 24px;
    font-family: var(--lato);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
}
#content nav::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    bottom: -40px;
    left: 0;
    border-radius: 50%;
    box-shadow: -20px -20px 0 var(--light);
}
#content nav a {
    color: var(--dark);
}
#content nav .bx.bx-menu {
    cursor: pointer;
    color: var(--dark);
}
#content nav .nav-link {
    font-size: 16px;
    transition: 0.3s ease;
}
#content nav .nav-link:hover {
    color: var(--blue);
}
#content nav form {
    max-width: 400px;
    width: 100%;
    margin-right: auto;
}
#content nav form .form-input {
    display: flex;
    align-items: center;
    height: 36px;
}
#content nav form .form-input input {
    flex-grow: 1;
    padding: 0 16px;
    height: 100%;
    border: none;
    background: var(--grey);
    border-radius: 36px 0 0 36px;
    outline: none;
    width: 100%;
    color: var(--dark);
}
#content nav form .form-input button {
    width: 36px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--blue);
    color: var(--light);
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 0 36px 36px 0;
    cursor: pointer;
}
#content nav .notification {
    font-size: 20px;
    position: relative;
}
#content nav .notification .num {
    position: absolute;
    top: -6px;
    right: -6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--light);
    background: var(--red);
    color: var(--light);
    font-weight: 700;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#content nav .profile img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 50%;
}
#content nav .switch-mode {
    display: block;
    min-width: 50px;
    height: 25px;
    border-radius: 25px;
    background: var(--grey);
    cursor: pointer;
    position: relative;
}
#content nav .switch-mode::before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: calc(25px - 4px);
    background: var(--blue);
    border-radius: 50%;
    transition: all 0.3s ease;
}
#content nav #switch-mode:checked + .switch-mode::before {
    left: calc(100% - (25px - 4px) - 2px);
}
/* NAVBAR */

/* MAIN */
#content main {
    width: 100%;
    padding: 36px 24px;
    font-family: var(--poppins);
    max-height: calc(100vh - 56px);
    overflow-y: auto;
}
#content main .head-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 16px;
    flex-wrap: wrap;
}
#content main .head-title .left h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--dark);
}
#content main .head-title .left .breadcrumb {
    display: flex;
    align-items: center;
    grid-gap: 16px;
}
#content main .head-title .left .breadcrumb li {
    color: var(--dark);
}
#content main .head-title .left .breadcrumb li a {
    color: var(--dark-grey);
    pointer-events: none;
}
#content main .head-title .left .breadcrumb li a.active {
    color: var(--blue);
    pointer-events: unset;
}
#content main .head-title .btn-download {
    height: 36px;
    padding: 0 16px;
    border-radius: 36px;
    background: var(--blue);
    color: var(--light);
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    font-weight: 500;
}

#content main .box-info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 24px;
    margin-top: 36px;
}
#content main .box-info li {
    padding: 24px;
    background: var(--light);
    border-radius: 20px;
    display: flex;
    align-items: center;
    grid-gap: 24px;
}
#content main .box-info li .bx {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}
#content main .box-info li:nth-child(1) .bx {
    background: var(--light-blue);
    color: var(--blue);
}
#content main .box-info li:nth-child(2) .bx {
    background: var(--light-yellow);
    color: var(--yellow);
}
#content main .box-info li:nth-child(3) .bx {
    background: var(--light-orange);
    color: var(--orange);
}
#content main .box-info li .text h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--dark);
}
#content main .box-info li .text p {
    color: var(--dark);
}

#content main .table-data {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 24px;
    margin-top: 24px;
    width: 100%;
    color: var(--dark);
}
#content main .table-data > div {
    border-radius: 20px;
    background: var(--light);
    padding: 24px;
    overflow-x: auto;
}
#content main .table-data .head {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    margin-bottom: 24px;
}
#content main .table-data .head h3 {
    margin-right: auto;
    font-size: 24px;
    font-weight: 600;
}
#content main .table-data .head .bx {
    cursor: pointer;
}

#content main .table-data .order {
    flex-grow: 1;
    flex-basis: 500px;
}
#content main .table-data .order table {
    width: 100%;
    border-collapse: collapse;
}
#content main .table-data .order table th {
    padding-bottom: 12px;
    font-size: 16px;
    text-align: left;
    border-bottom: 1px solid var(--grey);
    background: transparent;
}
#content main .table-data .order table td {
    padding: 16px 0;
}
#content main .table-data .order table tr td:first-child {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    padding-left: 6px;
}
#content main .table-data .order table td img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    object-fit: cover;
}
#content main .table-data .order table tbody tr:hover {
    background: var(--grey);
}
#content main .table-data .order table tr td .status {
    font-size: 10px;
    padding: 6px 16px;
    color: var(--light);
    border-radius: 20px;
    font-weight: 700;
}
#content main .table-data .order table tr td .status.completed {
    background: var(--blue);
}
#content main .table-data .order table tr td .status.process {
    background: var(--yellow);
}
#content main .table-data .order table tr td .status.pending {
    background: var(--orange);
}

#content main .table-data .product-home-admin {
    flex-grow: 1;
    flex-basis: 300px;
}
#content main .table-data .product-home-admin .products-list {
    width: 100%;
}
#content main .table-data .product-home-admin .products-list li {
    width: 100%;
    margin-bottom: 16px;
    background: var(--grey);
    border-radius: 10px;
    padding: 14px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#content main .table-data .product-home-admin .products-list li .bx {
    cursor: pointer;
}

#content main .table-data .product-home-admin .products-list li:last-child {
    margin-bottom: 0;
}

#content main .table-data .order table tr {
    text-align: left;
}

#content main .table-data .order table tr td {
    text-align: left;
}
/* MAIN */
/* CONTENT */

@media screen and (max-width: 768px) {
    #sidebar {
        width: 200px;
    }

    #content {
        width: calc(100% - 60px);
        left: 200px;
    }

    #content nav .nav-link {
        display: none;
    }
}

@media screen and (max-width: 576px) {
    #content nav form .form-input input {
        display: none;
    }

    #content nav form .form-input button {
        width: auto;
        height: auto;
        background: transparent;
        border-radius: none;
        color: var(--dark);
    }

    #content nav form.show .form-input input {
        display: block;
        width: 100%;
    }
    #content nav form.show .form-input button {
        width: 36px;
        height: 100%;
        border-radius: 0 36px 36px 0;
        color: var(--light);
        background: var(--red);
    }

    #content nav form.show ~ .notification,
    #content nav form.show ~ .profile {
        display: none;
    }

    #content main .box-info {
        grid-template-columns: 1fr;
    }

    #content main .table-data .head {
        min-width: 420px;
    }
    #content main .table-data .order table {
        min-width: 420px;
    }
    #content main .table-data .product-home-admin .products-list {
        min-width: 420px;
    }
}

.icon-home {
    width: 78px;
    height: 80px;
    border-radius: 10px;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--light-blue);
    color: var(--blue);
}

.icon-products-home {
    background: var(--light-yellow);
    color: var(--yellow);
}

.icon-users-home {
    background: var(--light-orange);
    color: var(--orange);
}

.text-sidebar {
    padding-left: 10px;
}

.product-home-admin {
    img {
        width: 50px;
    }
    p {
        margin-bottom: 0;
    }
}

td {
    p {
        margin-bottom: 0;
    }
}

// Products List
.listProducts {
    position: relative;
    width: calc(100% - 280px);
    left: 280px;
    transition: 0.3s ease;
    padding: 40px 20px;

    .title-products-list h3 {
        padding-bottom: 20px;
        font-size: 25px;
        font-weight: 600;
    }

    .add-product-admin {
        background-color: rgb(246, 95, 1);
        margin-bottom: 20px;
        height: 42px;
        color: #f2f2f2;
        border-radius: 30px;
        border: 1px solid rgb(246, 95, 1);
        padding: 0 15px;
        font-size: 16px;
        font-weight: 600;
        transition: all ease-out 0.3s;

        &:hover {
            opacity: 0.7;
        }
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }

    th,
    td {
        text-align: center;
        padding: 8px;
        img {
            width: 100px;
        }
    }

    td {
        border-bottom: 1px solid #e6e4e4;
    }

    th {
        color: #2c3135;
        background-color: #e9ecef;
        border-color: #dee2e6;
        text-align: center;
    }
}

th.th-id-product {
    width: 5%;
}

th.th-name-product {
    width: 20%;
}
th.th-image-product {
    width: 10%;
}
th.th-price-product {
    width: 10%;
}
th.th-decs-product {
    width: 35%;
}
th.th-act-product {
    width: 10%;
}

.desc-product-admin {
    max-width: 370px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.address-order-admin {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
}

.login-admin {
    min-height: 750px;
}

.delete-item-product-btn {
    background-color: transparent;
    border: 0;
    font-weight: 500;
}

.icon-home-admin {
    font-size: 23px;
    margin-right: 15px;
    // margin-left: 20px;
}

// .side-menu li {
//     margin-bottom: 10px;

//     a {
//         span {
//             font-size: 18px;
//         }
//     }
// }

.okkk {
    width: 100%;
    cursor: pointer;
}

p.okkk {
    margin-bottom: 0;
}

.brand-logo {
    text-align: center;
    padding-top: 30px;
    margin-bottom: 20px;
    // color: rgb(246, 95, 1);
    img {
        width: 80px;
    }
    p {
        font-weight: 600;
        font-size: 18px;
    }
}

.row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    margin: 0 -16px;
    box-sizing: border-box;
}

.col-25 {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
}

.col-50 {
    -ms-flex: 50%; /* IE10 */
    flex: 50%;
}

.col-75 {
    -ms-flex: 75%; /* IE10 */
    flex: 75%;
}

.col-25,
.col-50,
.col-75 {
    padding: 0 16px;
}

.container {
    // background-color: #f2f2f2;
    padding: 5px 20px 15px 20px;
    // border: 1px solid lightgrey;
    border-radius: 3px;

    input[type="text"],
    input[type="number"] {
        width: 100%;
        margin-bottom: 20px;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 3px;
    }
}

label {
    margin-bottom: 10px;
    display: block;
}

.icon-container {
    margin-bottom: 20px;
    padding: 7px 0;
    font-size: 24px;
}

.btn {
    background-color: #04aa6d;
    color: white;
    padding: 12px;
    margin: 10px 0;
    border: none;
    width: 100%;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
}

.btn:hover {
    background-color: #45a049;
}

a {
    color: #2196f3;
}

hr {
    border: 1px solid lightgrey;
}

span.price {
    float: right;
    color: grey;
}

/* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other (also change the direction - make the "cart" column go on top) */
@media (max-width: 800px) {
    .row {
        flex-direction: column-reverse;
    }
    .col-25 {
        margin-bottom: 20px;
    }
}

.select-category {
    width: 100%;
    margin-bottom: 20px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.select-order {
    width: 150px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 20px;
}

.select-role {
    .select-order {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 3px;
        margin-right: 20px;
    }
}

.add-pro-btn {
    background-color: rgb(246, 95, 1);
    margin-bottom: 20px;
    height: 42px;
    color: #f2f2f2;
    border-radius: 30px;
    border: 1px solid rgb(246, 95, 1);
    padding: 0 15px;
    font-size: 16px;
    font-weight: 600;
    transition: all ease-out 0.3s;

    &:hover {
        opacity: 0.7;
    }
}

th.th-id-order {
    width: 4%;
}
th.th-id-user {
    width: 6%;
}
th.th-fullname-order {
    width: 15%;
}
th.th-phone-order {
    width: 15%;
}
th.th-address-order {
    width: 20%;
}
th.th-payment-order {
    width: 10%;
}
th.th-status-order {
    width: 10%;
}
th.th-act-order {
    width: 10%;
}

// ----------------------------------------------------------------
th.th-name-product-order {
    width: 20%;
}
th.th-img-product-order {
    width: 20%;
}
th.th-price-product-order {
    width: 10%;
}
th.th-size-product-order {
    width: 10%;
}
th.th-quantity-product-order {
    width: 10%;
}
th.th-subtotal-product-order {
    width: 10%;
}

.save-btn {
    // width: 200px;
    padding: 12px 20px;
    margin-top: 20px;
    border: 1px solid #04aa6d;
    background-color: #04aa6d;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.5s;
    margin-bottom: 20px;

    &:hover {
        background-color: #11bc7d;
    }
}

.th-date-order {
    width: 15%;
}

.th-total-order {
    width: 15%;
}
