@import "../../scss/styles.scss";

.cart-page {
    margin: 80px auto;
    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
    }
    .cart-info {
        display: flex;
        flex-wrap: wrap;
        img {
            width: 100px;
        }
    }
}

th {
    text-align: left;
    padding: 5px;
    color: #fff;
    background: #efeeee;
    color: #333;
    font-weight: 600;
}

td {
    padding: 10px 5px;
    color: #333;
    input {
        width: 30px;
        height: 20px;
        padding: 5px;

        @include md {
            width: 40px;
            height: 30px;
            padding: 5px;
        }
    }
    a {
        color: #333;
        font-size: 16px;
        font-weight: 600;
    }

    .cart-info {
        img {
            width: 100px;
            // height: 150px;
            margin-right: 10px;
        }
        .small-price {
            float: left;
            font-size: 14px;
            color: #333;
        }
    }
}

.sec-cart {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;

    &:after {
        content: "";
        display: block;
        margin-top: 5px;
        width: 100px;
        height: 3px;
        background-color: #8e2de2;
    }
}

.total-price {
    display: flex;
    justify-content: flex-end;
    table {
        border-top: 2px solid #c7c7c7;
        width: 100%;
        max-width: 410px;
        button {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 500;
            padding: 10px 20px;
            cursor: pointer;
            border-radius: 50px;
            background-color: rgb(246, 95, 1);
            color: #fff;
            width: 120px;
            border: none;
            margin-bottom: 40px;
            &:hover {
                opacity: 0.8;
                transition: all ease 0.3;
            }
            a {
                color: #fff;
            }
        }
    }
}

td:last-child {
    text-align: right;
}

th:last-child {
    text-align: right;
}

tr:last-child {
    justify-content: flex-end;
    text-align: right;
}

.price {
    text-align: left;
    font-size: 14px;
    @include md {
        font-size: 16px;
    }
}

.delete {
    width: 5%;
}

.th_price {
    width: 10%;
    text-align: left;
}
.th_product {
    width: 40%;
    text-align: left;
}
.th_quantity {
    text-align: left;
    width: 20%;
}
.tru {
    background-color: transparent;
    font-size: 16px;
    width: 20px;
    height: 30px;
    border: none;
    border-top: 0.5px solid #969696;
    border-left: 0.5px solid #969696;
    border-bottom: 0.5px solid #969696;
    cursor: pointer;
    @include md {
        width: 35px;
        height: 40px;
    }
}

.input__cart {
    background-color: transparent;
    font-size: 16px;
    width: 30px;
    height: 30px;
    border: none;
    border: 0.5px solid #969696;
    text-align: center;
    cursor: pointer;
    &:focus-visible {
        outline: none;
    }
    @include md {
        width: 75px;
        height: 40px;
    }
}

.cong {
    background-color: transparent;
    font-size: 16px;
    width: 20px;
    height: 30px;
    border: none;
    border-top: 0.5px solid #969696;
    border-right: 0.5px solid #969696;
    border-bottom: 0.5px solid #969696;
    cursor: pointer;
    @include md {
        width: 35px;
        height: 40px;
    }
}

.css__input {
    text-align: left;
}

p {
    color: black !important;
    font-size: 15px;
    @include md {
        font-size: 16px;
    }
}

.name-product-cart {
    text-align: left;
}

.buy-btn {
    font-weight: 600 !important;
    font-size: 15px !important;
}

.empty_cart {
    text-align: center;
    padding: 30px 0;
    img {
        width: 300px;
    }
    .title_empty_cart {
        font-size: 18px;
        color: #555;
    }
}

.delete-item-cart-btn {
    border: 0;
    background-color: transparent;
    // font-weight: 700;
    font-size: 18px;
    cursor: pointer;
    color: #222;

    &:hover {
        color: #777;
    }
}
