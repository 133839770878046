@import "../../scss/styles.scss";

.container-infor {
    max-width: calc(100% - 20px);
    margin: 0 auto;
    @include md {
        max-width: 1100px;
    }
    .row-infor {
        display: flex;
        align-items: flex-start;
        padding: 50px 0;
        .col-4-infor {
            flex-basis: 35%;
            border: 1px solid #dcdbdb;
            padding: 20px;
            border-radius: 5px;
            margin-right: 20px;

            .avatar-infor {
                padding-top: 10px;
                text-align: center;
                img {
                    border-radius: 100%;
                    width: 130px;
                    height: 130px;
                    display: inline-block;
                    border: 2px solid rgb(246, 95, 1);
                }
            }

            .decs-infor {
                text-align: center;
                padding-top: 30px;
                p {
                    padding: 5px 0;
                    font-size: 17px;
                }
            }
        }
        .col-8-infor {
            flex-basis: 60%;
            border: 1px solid #dcdbdb;
            padding: 30px;
            border-radius: 5px;

            .information-label {
                font-size: 16px;
                color: #373941;
                display: inline-block;
                margin-bottom: 13px;
                cursor: pointer;
            }

            .information-input {
                display: block;
                width: 100%;
                height: 55px;
                padding: 25px;
                border-radius: 10px;
                background-color: #f2f3f5;
                outline: none;
                border: 0;
                font-size: 17px;
                margin-bottom: 15px;
            }

            .information-input::-webkit-input-placeholder {
                color: #b4bac0;
            }

            .information-submit {
                font-weight: 600;
                cursor: pointer;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                color: #fff;
                outline: none;
                border: 0;
                font-size: 17px;
                background-color: rgb(246, 95, 1);
                border-radius: 10px;
                text-align: center;
                display: inline-block;
                width: 100%;
                height: 48px;
                margin-top: 25px;
                margin-bottom: 20px;
            }
        }
    }
}

.sec-infor {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 50px;

    &:after {
        content: "";
        display: block;
        margin-top: 5px;
        width: 100px;
        height: 3px;
        background-color: #8e2de2;
    }
}
