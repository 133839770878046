.required-login {
    margin-top: -70px;

    .title-required-login {
        font-size: 20px;
        padding-bottom: 15px;
    }
}

a.required-login-a {
    font-size: 20px;
}
