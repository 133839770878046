@import "../../../scss/styles.scss";

.hero-banner {
    padding: 20px 0;
    position: relative;
    background-color: rgb(252, 239, 228);
    @include md {
        height: 400px;
    }
    .content {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column-reverse;
        max-width: calc(100% - 20px);
        margin: 0 auto;
        position: relative;
        @include md {
            flex-direction: row;
            max-width: 1200px;
        }

        .bannner-img {
            z-index: 9;
            width: 200px;
            margin-bottom: 20px;
            @include md {
                width: 600px;
                margin-right: 60px;
                margin-top: 50px;
                margin-bottom: 0px;
            }
            @include xxl {
                width: 600px;
                margin-right: 0px;
                margin-top: 0px;
            }
        }

        .text-content {
            color: #023d28;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            @include md {
                position: absolute;
                left: 50px;
                top: 50%;
                transform: translateY(-50%);
            }
            @include xxl {
                left: 0;
            }
            h1 {
                font: 60px;
                font-weight: 700;
                line-height: 1;
                margin-bottom: 20px;
                @include md {
                    font-size: 60px;
                }
            }
            p {
                max-width: 300px;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 20px;
                @include md {
                    max-width: 500px;
                    font-size: 18px;
                    line-height: 25px;
                    margin-bottom: 40px;
                }
            }
            .shopnow-btn {
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 500;
                padding: 10px 20px;
                cursor: pointer;
                border-radius: 50px;
                background-color: #023d28;
                color: #fff;
                width: 120px;
                &:hover {
                    opacity: 0.7;
                }
                a {
                    color: #fff;
                }
            }
        }
    }
}

.banner-product {
    width: 100%;
    padding-bottom: 50px;
    padding: 30px;
    padding-top: 0;

    img {
        height: 300px;
    }
}
