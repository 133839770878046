.register-container {
    background-color: #f2f3f5;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .register {
        margin-top: 40px;
        margin-bottom: 50px;
        padding: 27px 32px;
        background-color: #fff;
        width: 100%;
        max-width: 470px;
        border-radius: 20px;

        .register-heading {
            font-size: 25px;
            font-weight: bold;
            color: #373941;
            text-align: center;
            margin-bottom: 20px;
            margin-top: 15px;
        }

        .register-social {
            // font-family: "Poppins", sans-serif;
            padding: 10px;
            border-radius: 10px;
            width: 100%;
            height: 55px;
            color: #fff;
            display: block;
            font-size: 16px;
            font-weight: bold;
            border: 0px;
            background-color: #4c6ff4;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;
            // box-shadow: 0 5px 10px 0 rgba(76, 111, 244, 0.5);

            .register-social-icon {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                // font-size: 16px;
                padding: 10px;
                background-color: #fff;
            }

            .register-social-text {
                display: block;
                margin: 0 auto;
            }
        }

        .register-or {
            text-align: center;
            font-size: 16px;
            color: #666;
            margin: 15px auto;
        }

        .register-label {
            font-size: 16px;
            color: #373941;
            display: inline-block;
            margin-bottom: 13px;
            cursor: pointer;
        }

        .register-input {
            display: block;
            width: 100%;
            height: 55px;
            padding: 25px;
            border-radius: 10px;
            background-color: #f2f3f5;
            outline: none;
            border: 0;
            font-size: 17px;
            margin-bottom: 15px;
        }

        .register-input::-webkit-input-placeholder {
            color: #b4bac0;
        }

        .register-submit {
            font-weight: 600;
            cursor: pointer;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            color: #fff;
            outline: none;
            border: 0;
            font-size: 17px;
            background-color: rgb(246, 95, 1);
            border-radius: 10px;
            text-align: center;
            display: inline-block;
            width: 100%;
            height: 55px;
            margin-top: 25px;
            margin-bottom: 20px;
        }
        .register-resetpass {
            text-align: center;
            font-size: 16px;
            color: #666;
        }
        .register-link {
            color: #2979ff;
            text-decoration: none;
        }
    }
}
