@import "../../scss/styles.scss";

.small-container {
    max-width: 1000px;
    margin: auto;
    padding-left: 25px;
    padding-right: 20px;
}

.row {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    display: block;
    @include md {
        display: flex;
    }
}

// .single-product {
//     margin-top: 50px;
// }

.small-img-row {
    display: flex;
    justify-content: space-around;
}

#imgProduct {
    border-radius: 5px;
}

img {
    width: 100%;
    border-radius: 4px;
}

.col-2-detail img {
    max-width: 100%;
    padding: 50px 0;
}

.nameProduct {
    font-weight: 600;
}

.small-img-col {
    padding-top: 5px;
    flex-basis: 24%;
    cursor: pointer;
}

.single-product .col-2-detail img {
    padding: 0;
}

.single-product .col-2-detail {
    padding: 20px;
}

.col-2-detail {
    flex-basis: 50%;
    min-width: 300px;
}

.col-2-detail h1 {
    font-style: 50px;
    // line-height: 20px;
    margin: 25px 0;
}

.single-product select {
    display: block;
    padding: 10px;
    margin-top: 20px;
    border: 1.3px solid #a6a6a6;
    display: block;
    padding: 10px;
    margin-top: 20px;
    background-color: #f6f6f6;
    cursor: pointer;
    border-radius: 30px;
    width: 140px;
    font-weight: 600;
}

select:focus-visible {
    outline: none;
}

.single-product h4 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
}

a,
p {
    text-decoration: none;
    color: #555;
}

a.btn-buy-detail.buy-now {
    display: inline-block;
    background-color: rgb(246, 95, 1);
    color: #fff;
    padding: 8px 5px;
    margin-bottom: 20px;
    border-radius: 30px;
    transition: all ease-in-out 0.3s;
    margin-right: 10px;
    min-width: 140px;
    text-align: center;
    font-weight: 600;

    &:hover {
        opacity: 0.7;
        text-decoration: none;
    }
    @include md {
        padding: 8px 30px;
        min-width: 190px;
    }
}

button.add-to-cart-detail-btn {
    background-color: transparent;
    border: 0;
    font-weight: 600;
}

.buy-now-detail-btn {
    background-color: rgb(246, 95, 1);
    border: 0;
    font-weight: 600;
    color: #fff;
}

.add-to-cart {
    text-decoration: none;
    display: inline-block;
    background-color: transparent;
    color: rgb(246, 95, 1);
    padding: 8px 5px;
    margin: 10px 30px 0 0;
    border-radius: 30px;
    margin-bottom: 20px;
    min-width: 140px;
    text-align: center;
    font-weight: 600;
    border: 1.5px solid rgb(246, 95, 1);
    &:hover {
        opacity: 0.7;
        color: rgb(246, 95, 1);
        text-decoration: none;
    }
    @include md {
        padding: 8px 30px;
        min-width: 190px;
    }
}
// }

// .buy-now {
//     color: #fff;
//     background-color: rgb(246, 95, 1);
//     border: 1.7px solid rgb(246, 95, 1);
// }

.margin_top {
    margin-top: 0px;
    @include md {
        // margin-top: -100px;
        align-items: flex-start;
        justify-content: center;
    }
}

.input-quantity {
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 10px;
    cursor: pointer;
    .tru {
        background-color: #f6f6f6;
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        width: 40px;
        height: 40px;
        font-size: 26px;
        border-color: #f6f6f6;
        border: none;
        outline: none;
        padding: 0 auto;
    }

    .cong {
        background-color: #f6f6f6;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        width: 40px;
        height: 40px;
        font-size: 26px;
        border-color: #f6f6f6;
        border: none;
        outline: none;
    }

    .input {
        background-color: #f6f6f6;
        width: 40px;
        height: 40px;
        font-size: 26px;
        border-color: #f6f6f6;
        border: none;
        outline: none;
        text-align: center;
    }
    .title-quantity-product {
        padding-top: 17px;
    }
}

.about-product {
    font-size: 15px;
    color: #333;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tag-hr {
    color: #d3d1d1;
}

.hr1 {
    margin-top: 20px;
}

.size-quantity {
    display: flex;
    font-size: 13px;
}

.style-about-quantity {
    font-weight: 700;
    color: rgb(246, 95, 1);
}

// .term-detail {
//     margin-top: 50px;
// }

.delivery {
    width: 100%;
    border: 1px solid #a6a6a6;
    // border-radius: 4px;
    padding: 10px 10px;
    color: #333;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    h3 {
        font-size: 15px;
        color: #333;
        // font-weight: normal;
    }
    p {
        font-size: 15px;
        color: #333;
    }
}

.d1 {
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.icon-ship {
    color: rgb(246, 95, 1);
    font-size: 18px;
    font-weight: 600;
    margin-right: 7px;
}

// .add-to-cart {
//     color: rgb(246, 95, 1);
//     border: 1.7px solid rgb(246, 95, 1);
//     background-color: transparent;
// }

.single-product {
    display: flex;
    padding-top: 40px;
}

.title-select-size {
    font-size: 20px;
    color: #333;
    padding: 10px 0;
    padding-bottom: 0;
    font-weight: 600;
}

.select-size {
    display: flex;
    margin: 0;
    padding: 0px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
    cursor: pointer;
}

.select-size div {
    margin: 10px;
}

.select-size div label {
    cursor: pointer;
    background: rgb(255, 255, 255);
    color: rgb(17, 17, 17);
}

.select-size div label input[type="radio"] {
    display: none;
}

.select-size div label span {
    position: relative;
    display: inline-block;
    background: transparent;
    padding: 8px 12px;
    color: #555;
    transition: 0.5s;
    user-select: none;
    overflow: hidden;
    outline: none;
    align-items: center;
    height: 60px;
    min-width: 125px;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    color: rgb(17, 17, 17);
    box-shadow: rgb(229, 229, 229) 0px 0px 0px 1px;
    margin: 2px 4px;
    border: 1px solid rgb(229, 229, 229) 0px 0px 0px 1px;
}

.select-size div label input[type="radio"]:checked ~ span {
    border: 1px solid #7d7d7d;
    outline: none;
}

.quantity-product-detail {
    font-size: 13px;
    color: rgb(246, 95, 1) !important;
}

.view-product {
    float: right;
}
