@import "../../scss/styles.scss";

.main-header {
    width: 100%;
    padding: 0 20px;
    background-color: #ffffff;
    color: #333;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 99;
    min-height: 60px;
    text-align: center;
    padding-top: 15px;
    text-decoration: none;

    @include md {
        padding: 0 40px;
        min-height: 60px;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        @include md {
            height: 70px;
        }

        .left {
            font-size: 25px;
            font-weight: 700;
            cursor: pointer;
            font-style: 34px;
            left: 50%;
            a {
                color: #333;
            }
        }

        .center {
            list-style-type: none;
            display: none;
            gap: 30px;
            @include md {
                display: flex;
            }
            li {
                font-size: 16px;
                font-weight: 600;
                text-transform: uppercase;
                cursor: pointer;
                a {
                    color: #333;
                }
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 20px;
            @include md {
                gap: 25px;
            }

            .search {
                display: flex;
                height: 40px;
                padding: 0px 10px;
                padding-left: 20px;

                @include md {
                    display: flex;
                }

                input {
                    background-color: transparent;
                    width: 240px;
                    border: none;
                    outline: none;
                    font-size: 16px;
                }
                .search-btn,
                fa {
                    cursor: pointer;
                    border: none;
                    outline: none;
                    background-color: transparent;
                }
            }

            svg {
                font-size: 20px;
                cursor: pointer;
                @include md {
                    font-style: 24px;
                }
            }
            .cart-icon {
                position: relative;
                color: #333;
                span {
                    min-width: 20px;
                    text-align: center;
                    background-color: rgb(226, 69, 69);
                    padding: 2.5px;
                    position: absolute;
                    top: -5px;
                    right: -12px;
                    font-size: 12px;
                    line-height: 1;
                    border-radius: 10px;
                    color: #fff;
                }
            }
        }
    }

    &.sticky-header {
        position: sticky;
        top: 0;
        animation: stickyHeader 0.3s ease forwards;
    }
}

ul.center {
    margin-bottom: 0;
}

@keyframes stickyHeader {
    0% {
        transform: translateY(-70px);
    }
    100% {
        transform: translateY(0px);
    }
}

.account {
    z-index: 100000000;

    padding: auto;
    cursor: pointer;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    // justify-content: center;
    max-width: 150px;
    overflow: hidden;

    .icon-account {
        margin-bottom: -3px;
    }
    .avatarUser {
        width: 45px;
        // border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .userName {
        padding-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.dropdown-btn {
    .drop-item {
        display: flex;
        flex-direction: column;
        background-color: red;
    }
}

.dropbtn {
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    cursor: pointer;
}

.dropdown-css {
    color: red;
    text-decoration: none;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
    color: #333;
    text-decoration: none;
}

.dropdown:hover .dropdown-content {
    display: block;
}

// .dropdown:hover .dropbtn {
//     background-color: rgba(0, 0, 0, 0.1);
// }
