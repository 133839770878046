@import "../../scss/styles.scss";

.changepass-container {
    background-color: #f6f7f8;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .changepass {
        margin-top: 40px;
        margin-bottom: 50px;
        padding: 27px 32px;
        background-color: #fff;
        width: 100%;
        max-width: 600px;
        border-radius: 20px;

        .changepass-heading {
            font-size: 25px;
            font-weight: bold;
            color: #373941;
            text-align: center;
            margin-bottom: 20px;
            margin-top: 15px;
        }

        .changepass-social {
            padding: 10px;
            border-radius: 10px;
            width: 100%;
            height: 55px;
            color: #fff;
            display: block;
            font-size: 16px;
            font-weight: bold;
            border: 0px;
            background-color: #4c6ff4;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            outline: none;

            .changepass-social-icon {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                background-color: #fff;
            }

            .changepass-social-text {
                display: block;
                margin: 0 auto;
            }
        }

        .changepass-or {
            text-align: center;
            font-size: 16px;
            color: #666;
            margin: 15px auto;
        }

        .changepass-label {
            font-size: 16px;
            color: #373941;
            display: inline-block;
            margin-bottom: 13px;
            cursor: pointer;
        }

        .changepass-input {
            display: block;
            width: 100%;
            height: 55px;
            padding: 25px;
            border-radius: 10px;
            background-color: #f2f3f5;
            outline: none;
            border: 0;
            font-size: 17px;
            margin-bottom: 15px;
        }

        .changepass-input::-webkit-input-placeholder {
            color: #b4bac0;
        }

        .changepass-submit {
            font-weight: 600;
            cursor: pointer;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
            color: #fff;
            outline: none;
            border: 0;
            font-size: 17px;
            background-color: rgb(246, 95, 1);
            border-radius: 10px;
            text-align: center;
            display: inline-block;
            width: 100%;
            height: 55px;
            margin-top: 25px;
            margin-bottom: 20px;
        }
        .changepass-resetpass {
            text-align: center;
            font-size: 16px;
            color: #666;
        }
        .changepass-link {
            color: #2979ff;
            text-decoration: none;
        }
    }
}

.sec-changepass {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding-top: 20px;
    padding-bottom: 50px;

    &:after {
        content: "";
        display: block;
        margin-top: 5px;
        width: 100px;
        height: 3px;
        background-color: #8e2de2;
    }
}
