@import "../../scss/styles.scss";

.sec-heading {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    @include md {
        margin-bottom: 35px;
        font-size: 21px;
    }
    &:after {
        content: "";
        display: block;
        margin-top: 5px;
        width: 100px;
        height: 3px;
        background-color: #8e2de2;
        @include md {
            margin-top: 10px;
        }
    }
}

.main-content {
    .layout {
        max-width: calc(100% - 20px);
        margin: 0 auto;
        @include md {
            max-width: 1100px;
        }
    }
}
