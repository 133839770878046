.wrapper-about {
    padding: 80px 0;
}

.row-about {
    display: flex;
    flex-wrap: wrap;
}

.flex-about {
    // flex: 0 0 50px;
    max-width: 50%;
    padding: 0 20px;
}

.wrapper-about h2 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.wrapper-about h3 {
    font-size: 22px;
    margin-bottom: 8px;
    color: #333;
}

.wrapper-about p {
    font-size: 18px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 20px;
}

.wrapper-about img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.social-link-about {
    margin-bottom: 20px;
    display: flex;
}

.social-link-about a {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    color: #333;
    font-weight: bold;
    background-color: #fff;
    border: 1.5px solid #333;
    transition: all 0.4s ease;
    font-size: 18px;
    &:hover {
        transform: translateY(-3px);
    }
}

.btn-about {
    text-decoration: none;
    color: #fff;
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    background-color: #333;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

    &:hover {
        transform: translateY(-3px);
    }
}

@media screen and(max-width: 768px) {
    .row-about {
        flex-direction: column;
    }
    .flex-about {
        max-width: 100%;
    }
    .wrapper-about h2 {
        font-size: 31px;
    }
    .wrapper-about p {
        font-size: 16px;
    }
    .social-link-about a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        margin-right: 5px;
    }
    .btn-about {
        font-size: 16px;
        padding: 8px 16px;
        margin-bottom: 30px;
    }
}
